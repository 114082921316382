import React from "react";
import "./About.css";
import background from "../Background4.png";

const About = () => {
  return (
    <section className="about section-p bg-dark" id="about">
      <img id="frontpageimage" src={background} border="0px" />
      <div className="container">
        <div className="about-content grid text-center">
          <div className="content-left"></div>
          <div className="content-right">
            <div className="title">
              <h3>Who We Are</h3>
            </div>
            <ul>
              <li>
                {" "}
                <p className="text">
                  RTH SMSF Audits is an Audit provider focused on independent
                  SMSF audits for SMSF trustees and accounting firms.
                </p>
              </li>
              <li>
                {" "}
                <p className="text">
                  Rose Thomas is an ASIC Approved SMSF Auditor and a member of
                  the Chartered Accountants Australia and New Zealand.
                </p>
              </li>
              <li>
                {" "}
                <p className="text">
                  We pride ourselves on providing an efficient and thorough
                  service with a short turnaround.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
