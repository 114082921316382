import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useState, useEffect } from "react";
import "./Contact.css";
import { useCallback } from "react";

function onChange(value) {
  console.log("Captcha value:", value);
}

function Contact() {
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const [data, setData] = React.useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    const information = {
      name: inputs.username,
      content: inputs.inquiry,
      phone: inputs.phone,
      email: inputs.email,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(information),
    };
    fetch("/apicontact", options)
    setData(previousState => {
      return "Contact Request Sent!"
    });
  };
  const [disableSubmit, setDisableSubmit] = useState(true);
 // "proxy": "http://localhost:3001",
  return (
    <section className="contact" id="contact">
      <div className="container">
        <div className="contact-content grid text-center">
          <div className="contact-left">
            <div className="title">
              <h3>How to contact us</h3>
            </div>
            <p className="text">
              You can contact us via email or phone, using the details below{" "}
            </p>
            <ul>
              <li>
                <p className="text">Email: audit@rthsmsfaudits.com.au</p>
              </li>
              <li>
                <p className="text">Phone: 0472 501 409</p>
              </li>
            </ul>
            <div className="title">
              <h3>Contact Form</h3>
            </div>
            <p className="text">Or contact us using the contact form below.</p>
            <form onSubmit={handleSubmit}>
              <label>
                Name * <br />
                <input
                  type="text"
                  name="username"
                  value={inputs.username || ""}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Email Address * <br />
                <input
                  type="email"
                  name="email"
                  value={inputs.email || ""}
                  onChange={handleChange}
                  required
                />
              </label>
              <br />
              <label>
                Phone Number <br />
                <input
                  type="number"
                  name="phone"
                  value={inputs.phone || ""}
                  onChange={handleChange}
                />
              </label>
              <br />
              <label>
                Inquiry *
                <br />
                <textarea
                  id="inquiry"
                  name="inquiry"
                  rows="4"
                  cols="50"
                  value={inputs.inquiry || ""}
                  onChange={handleChange}
                ></textarea>
              </label>
              <center>
                <ReCAPTCHA
                  sitekey="6LcevpUpAAAAAAo0-QTF48m8idSvVhJC3BTTig5Q"
                  onChange={useCallback(() => setDisableSubmit(false))}
                />
              </center>
              {!data && <input type="submit" disabled={disableSubmit} id="submitButton" />}
            </form>

            <center><div className="App">
              <header className="App-header">
                <p>{!data ? "" : data}</p>
              </header>
            </div></center>
            <div className="contact-right"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
//<img src = {images.form_main_img} alt = "" />
