import React from "react";
import calogo from "./calogo.jpg";

function Footer() {
  const footerstyle = {
    width: "100%",
    "text-align": "center",
    margin: "0 auto",
    "max-width": "1160px",
    padding: "0 2rem",
    "margin-top": "3rem",
  };
  return (
    <footer style={footerstyle}>
      <img height="110px" src={calogo} />
      <p style={{ fontSize: 11 + "px" }}>
        {" "}
        ABN 64 345 203 493 <br />
        RTH SMSF Audits, All rights reserved. <br />
        Liability limited by a scheme approved under Professional Standards
        Legislation.
      </p>
    </footer>
  );
}

export default Footer;
