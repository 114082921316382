import React from "react";
import "./About.css";
import background from "../Background4.png";

function Services() {
  return (
    <section className="about section-p bg-dark" id="about">
      <img id="frontpageimage" src={background} border="0px" />
      <div className="container">
        <div className="about-content grid text-center">
          <div className="content-left"></div>
          <div className="content-right">
            <div className="title">
              <h3>SMSF Audits</h3>
            </div>
            <p className="text">
              We provide a fully independent audit service to SMSF trustees and
              Accounting Practices. <br /> <br /> We aim to provide an efficient
              turnaround (dependent on documentations provided).
            </p>
            <div className="title">
              <h3>Other Services:</h3>
            </div>
            <p className="text">
              <h3>RTH Accounting and Taxation Services </h3>
              We specialise in accounting and taxation for all types of entities
              and individuals. <br /> <br />
              We will help you build your business by taking away all your
              stress and managing tax affairs for you.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
