import React from "react";
import "./About.css";
import background from "../Background4.png";

function Home() {
  return (
    <section className="about section-p bg-dark" id="about">
      <img id="frontpageimage" src={background} border="0px" />
      <div className="container">
        <div className="about-content grid text-center">
          <div className="content-left"></div>
          <div className="content-right">
            <div className="title">
              <h3>RTH SMSF Audits & Accounting</h3>
            </div>
            <p className="text">
              RTH Accounting has been helping to provide services for the last
              16 years.
              <br />
              <br />
              We offer:
            </p>
            <ul>
              <li>
                {" "}
                <p className="text">Australian Based SMSF Audit Services</p>
              </li>
              <li>
                {" "}
                <p className="text">Providing Independence for accountants</p>
              </li>
              <li>
                {" "}
                <p className="text">Accounting and Taxation Services</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
